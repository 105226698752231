import React from "react";
import { Select } from "../../../helpers";
import { countries } from "../../../../geo";
import { FilterMainComponentProps } from "../Filter";

const formattedCountries = [
  { value: "", label: "All" },
  ...countries.map((item) => ({
    value: item,
    label: item,
  })),
];

const CountrySelect = ({
  values,
  onChange,
  placeholder = "All",
}: FilterMainComponentProps) => {
  return (
    <Select
      value={values}
      // @ts-ignore
      onChange={(e: { value: string; label: string }) =>
        onChange(e?.value || null)
      }
      isClearable={values !== ""}
      // @ts-ignore
      options={formattedCountries}
      placeholder={placeholder}
    />
  );
};

export default CountrySelect;
